<template>
<div>
  <b-form-group>
    <div class="d-flex align-items-center">
      <label class="mr-1">Введите номер старта</label>
      <b-form-input
          v-model="currentStart"
          placeholder="Search"
          type="text"
          class="d-inline-block"
      />
    </div>
  </b-form-group>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">
        Банковская статистика
      </h4>
    </div>
    <div class="card-body">
      <start-statistic :start_id="currentStart" />
    </div>
  </div>
</div>
</template>

<script>
import {VueGoodTable} from "vue-good-table";
import {BBadge, BButton, BDropdown, BDropdownItem, BFormCheckbox, BFormGroup, BFormInput} from "bootstrap-vue";
import InfiniteLoading from "vue-infinite-loading";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import crudModule from "@/libs/crud_module";
import StartStatistic from '../starts/StartStatistic'

const startModule = crudModule({
  entity_name: 'start',
})
export default {
  components: {
    VueGoodTable,
    BBadge,
    StartStatistic,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BButton,
    InfiniteLoading,
    BFormCheckbox,
    // ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      skipCountStarts: 6,
      maxResultCountStarts: 9999,
      modalIsOpen: false,
      startToDelete: null,
      currentStart: null,
      rows: [],
      pageLength: '',
      dir: false,
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        }],
    }
  },

  async created() {
    try {
      const payload = {
        skipCount: '',
        maxResultCount: this.maxResultCountStarts,
      }
      this.rows = await startModule.fetchItems(payload)
      .then(res => res.data.rows)
    } catch (e) {
      console.error(e)
    }
  }
  }
</script>

<style scoped>

</style>